@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

body {
	margin: 0;
	padding: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


#navbar ul li {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.btn:hover {
	background-color: #ECE7EB !important;
	color: black !important;
}

.btn:active {
	transform: translateY(7px) !important;
}

